import React from 'react'
import { Button } from 'reactstrap'
import CIcon from '@coreui/icons-react'
import {
  cilActionUndo,
  cilDelete,
  cilNoteAdd,
  cilOptions,
  cilPencil,
  cilPrint,
  cilSave,
  cilWarning,
} from '@coreui/icons'
import { Style } from '../constants'

export function AddButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="primary"
      style={{ padding: 2, fontSize: 14 }}
      type="button"
      onClick={props.onClick}
      {...restProps}
    >
      <CIcon icon={icon ? icon : cilNoteAdd} /> {caption ? caption : 'Tambah'}
    </Button>
  )
}

export function EditButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="primary"
      outline
      style={{ padding: 2, fontSize: 13 }}
      type="button"
      onClick={props.onClick}
      {...restProps}
    >
      <CIcon icon={icon ? icon : cilPencil} /> {caption ? caption : 'Edit'}
    </Button>
  )
}

export function DeleteButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="danger"
      style={{ padding: 2, fontSize: 13 }}
      type="button"
      onClick={props.onClick}
      outline
      {...restProps}
    >
      <CIcon icon={icon ? icon : cilDelete} /> {caption ? caption : 'Delete'}
    </Button>
  )
}

export function DetailButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="primary"
      style={{ padding: 2, fontSize: 13 }}
      type="button"
      onClick={props.onClick}
      {...restProps}
    >
      <CIcon icon={icon ? icon : cilOptions} /> {caption ? caption : 'Detail'}
    </Button>
  )
}

export function SubmitButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="primary"
      style={{ padding: 2, fontSize: 13 }}
      type="button"
      onClick={props.onClick}
      {...restProps}
    >
      <CIcon icon={icon ? icon : cilSave} /> {caption ? caption : 'Simpan'}
    </Button>
  )
}

export function CancelButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="primary"
      style={{ padding: 2, fontSize: 13 }}
      type="button"
      outline
      onClick={props.onClick}
      {...restProps}
    >
      <CIcon icon={icon ? icon : cilActionUndo} /> {caption ? caption : 'Batal'}
    </Button>
  )
}

export function ConfirmButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="primary"
      style={{ padding: 2, fontSize: 13 }}
      type="button"
      onClick={props.onClick}
      outline
      {...restProps}
    >
      <CIcon icon={icon ? icon : cilWarning} /> {caption ? caption : 'Konfirmasi'}
    </Button>
  )
}

export function PrintButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="primary"
      outline
      style={{ padding: 2, fontSize: 13 }}
      type="button"
      onClick={props.onClick}
      {...restProps}
    >
      <CIcon icon={icon ? icon : cilPrint} /> {caption ? caption : 'Cetak'}
    </Button>
  )
}

export function CommonButton(props) {
  const { icon, caption, ...restProps } = props
  return (
    <Button
      size={Style.size}
      color="primary"
      style={{ padding: 2, fontSize: 13 }}
      type="button"
      onClick={props.onClick}
      {...restProps}
    ></Button>
  )
}
