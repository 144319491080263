import React from 'react'

function ContainerFullscreen(props) {
  const { children, fullscreen } = props
  return (
    <div
      style={
        fullscreen
          ? {
              background: 'white',
              padding: '20px 20px 10px 20px',
              marginBottom: '25px',
            }
          : {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              top: 0,
              position: 'fixed',
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1049,
              background: 'white',
              padding: '20px 20px 0 20px',
            }
      }
    >
      {children}
    </div>
  )
}

export default ContainerFullscreen
