import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'

//import 'toasted-notes/src/styles.css'
import 'react-datepicker/dist/react-datepicker.css'

import './axiosSetup'

import { AuthProvider } from './views/Auth/AuthContext'
import { Loading } from './views/Common/Components'
/*
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
*/

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
//const Layout = React.lazy(() => import('./views/Layout'))

// Pages
//const Login = React.lazy(() => import('./views/pages/login/Login'))
//const Register = React.lazy(() => import('./views/pages/register/Register'))
//const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
//const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Login = React.lazy(() => import('./views/Auth/LoginPage'))
const EnvInfo = React.lazy(() => import('./views/EnvInfo'))
const ForgotPasswordPage = React.lazy(() => import('./views/Auth/ForgotPasswordPage'))

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <HashRouter>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/forgot-password"
                name="Forgot Password"
                render={(props) => <ForgotPasswordPage {...props} />}
              />
              <Route
                exact
                path="/_env"
                name="Env Info"
                render={(props) => <EnvInfo {...props} />}
              />
              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
              {/*<Route path="/" name="Home" render={(props) => <Layout {...props} />} />*/}
            </Switch>
          </React.Suspense>
        </HashRouter>
      </AuthProvider>
    )
  }
}

export default App
